// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useState } from 'react';
import { Table } from '@cloudscape-design/components';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Alert from '@cloudscape-design/components/alert';
import Box from '@cloudscape-design/components/box';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import Tabs from '@cloudscape-design/components/tabs';
import Link from '@cloudscape-design/components/link';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Header from '@cloudscape-design/components/header';
import CopyToClipboard from '@cloudscape-design/components/copy-to-clipboard';
import { GroupedBarChart } from '@carbon/charts-react';
import '@carbon/charts-react/styles.css';

import ExportExcelAll from './ExportExcelAll';
import { BestPriceSummary } from './BestPriceSummary';

export function EstimateView(props) {
  const [showAdditionalComponent, setShowAdditionalComponent] = useState(false);

  const handleCheckboxConfirm = () => {
    setShowAdditionalComponent(true);
  };

  const {
    estimateClassicMultiAz,
    estimateClassicSingleAz,
    estimateHCP,
    estimateOCPMultiAz,
    tableItems,
    hasNodes,
    ebsPrices,
    ec2Prices,
    error,
    errorReason,
    region,
  } = props;

  if (error) {
    return (
      <Alert statusIconAriaLabel="Error" type="error" header={`${error} (AWS Region code: ${region})`}>
        {errorReason && errorReason}
        {!errorReason && 'Please contact your administrator.'}
      </Alert>
    );
  }

  if (!hasNodes || !region) {
    return (
      <Alert statusIconAriaLabel="Info" header={'Verify your configuration'}>
        To include you worker node sizes in the estimate,
        <ul>
          <li>select a region</li>
          <li>edit the number of instances for each Amazon EC2 instance type</li>
        </ul>
      </Alert>
    );
  }

  const display = ec2Prices && ebsPrices && estimateClassicSingleAz && estimateClassicMultiAz;

  let displayNodes = [];
  tableItems.forEach(element => {
    if (element.count > 0) {
      displayNodes.push(`${element.count}x ${element.id}`);
    }
  });

  const currentUrl = window.location.origin + location.pathname + location.search + location.hash;

  return (
    display && (
      <>
        {!showAdditionalComponent && (
          <Alert statusIconAriaLabel="Warning" type="warning">
            Please note that pricing may vary based on specific factors and is subject to change by the service provider
            without notice.
            <br />
            <br />
            This calculator provides the <strong>public</strong> pricing of OpenShift offerings on AWS: Red Hat
            OpenShift Service on AWS (ROSA) and OpenShift Container Platform (OCP).
            <br />
            For <strong>private</strong> pricing on longer commitments (1 year or 3 years), please contact: &nbsp;
            <strong>
              <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
            </strong>
            <br />
            <br />
            By using this calculator, you acknowledge that the results are for informational purposes.
            <br />
            You should consult the official website or contact the service provider directly for accurate pricing
            details.
            <br />
            <br />
            <label>
              <input type="checkbox" onChange={handleCheckboxConfirm} /> I acknowledge and accept the disclaimer
            </label>
          </Alert>
        )}

        {showAdditionalComponent && (
          <>
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <ExportExcelAll
                    sheets={[
                      {
                        data: getEstimateItemsSummary(estimateClassicMultiAz, estimateHCP),
                        title: 'Summary',
                        format: false,
                      },
                      {
                        data: getEstimateItems(estimateHCP),
                        title: 'HCP - Multi-AZ',
                        format: true,
                      },
                      {
                        data: getEstimateItems(estimateClassicMultiAz),
                        title: `Classic - Multi-AZ`,
                        format: true,
                      },
                      {
                        data: getEstimateItems(estimateClassicSingleAz),
                        title: 'Classic - Single-AZ',
                        format: true,
                      },
                    ]}
                    fileName={'rosa-cost-estimate'}
                  />

                  <CopyToClipboard copyButtonText="Share" copySuccessText="Link copied" textToCopy={currentUrl} />
                </SpaceBetween>
              }
            />
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  id: 'summary',
                  content: (
                    <SpaceBetween size="l">
                      <Header variant="h3">
                        ROSA Multi-AZ / Worker node types: <strong>{displayNodes.join(', ')}</strong>
                      </Header>
                      <BestPriceSummary tableItems={tableItems} ec2Prices={ec2Prices} />
                      <ColumnLayout columns={2}>
                        <SummaryTable estimateClassic={estimateClassicMultiAz} estimateHCP={estimateHCP} />
                        <SummaryBarChart estimateClassic={estimateClassicMultiAz} estimateHCP={estimateHCP} />
                        {/* <br />
                          <small>RI: Reserved Instances | SP: Savings Plans</small>
                          <small>
                            ROSA versions: Classic, HCP (Hosted Control Planes).{' '}
                            <Link
                              external
                              href="https://docs.aws.amazon.com/ROSA/latest/userguide/rosa-deployment-options.html"
                            >
                              <small>See Deployment options and HCP availability</small>
                            </Link>
                          </small> */}
                      </ColumnLayout>
                      {/* <Container
                      header={<Header variant="h3">Single-AZ / Worker node types: {displayNodes.join(', ')}</Header>}
                    >
                      <SummaryTable estimateClassic={estimateClassicSingleAz} estimateHCP={estimateHCP} />
                    </Container> */}
                    </SpaceBetween>
                  ),
                },
                {
                  label: 'ROSA HCP Multi-AZ',
                  id: 'hcp-multi-az',
                  content: (
                    <>
                      <SpaceBetween size="s">
                        <EstimateTable
                          estimate={estimateHCP}
                          header={
                            <SpaceBetween direction="vertical" size="xs">
                              <Alert statusIconAriaLabel="Info">
                                <strong>Worker nodes selected:</strong> {displayNodes.join(', ')}
                              </Alert>
                              <BestPriceSummary tableItems={tableItems} ec2Prices={ec2Prices} />
                            </SpaceBetween>
                          }
                        />
                        <EstimateLegend />
                      </SpaceBetween>
                    </>
                  ),
                },
                {
                  label: 'ROSA Classic Multi-AZ',
                  id: 'classic-multi-az',
                  content: (
                    <>
                      <SpaceBetween size="s">
                        <EstimateTable
                          estimate={estimateClassicMultiAz}
                          header={
                            <SpaceBetween direction="vertical" size="xs">
                              <Alert statusIconAriaLabel="Info">
                                <strong>Worker nodes selected:</strong> {displayNodes.join(', ')}
                              </Alert>
                              <BestPriceSummary tableItems={tableItems} ec2Prices={ec2Prices} />
                            </SpaceBetween>
                          }
                        />
                        <EstimateLegend />
                      </SpaceBetween>
                    </>
                  ),
                },
                {
                  label: 'ROSA Classic Single AZ',
                  id: 'classic-single-az',
                  content: (
                    <>
                      <SpaceBetween size="s">
                        <EstimateTable
                          estimate={estimateClassicSingleAz}
                          header={
                            <SpaceBetween direction="vertical" size="xs">
                              <Alert statusIconAriaLabel="Info">
                                <strong>Worker nodes selected:</strong> {displayNodes.join(', ')}
                              </Alert>
                              <BestPriceSummary tableItems={tableItems} ec2Prices={ec2Prices} />
                            </SpaceBetween>
                          }
                        />
                        <EstimateLegend />
                      </SpaceBetween>
                    </>
                  ),
                },

                {
                  label: 'OCP self-managed on AWS - Multi-AZ',
                  id: 'ocp-multi-az',
                  content: (
                    <>
                      <SpaceBetween size="s">
                        <EstimateTable
                          estimate={estimateOCPMultiAz}
                          header={
                            <SpaceBetween direction="vertical" size="xs">
                              <Alert statusIconAriaLabel="Info">
                                <strong>Worker nodes selected:</strong> {displayNodes.join(', ')}
                              </Alert>
                              <BestPriceSummary tableItems={tableItems} ec2Prices={ec2Prices} />
                            </SpaceBetween>
                          }
                        />
                        <EstimateLegend />
                      </SpaceBetween>
                    </>
                  ),
                },
                {
                  label: 'FAQ',
                  id: 'resources',
                  content: (
                    <>
                      {' '}
                      <ExpandableSection headerText="AWS Saving Plans - which one to choose?">
                        <ul>
                          <li>
                            Compute Savings Plans: provides the most flexibility. Results in savings up to 66% when
                            compared to on demand pricing. Automatically applies to EC2 usage regardless of instance AZ,
                            AZ, Region, OS or tenancy. It also applies to Fargate or Lambda usage. For example,
                            customers can change instance families from say C4 to M5, migrate a workload across Regions,
                            or move a workload from EC2 to Lambda at any time and continue to pay the Savings Plans
                            price.
                          </li>
                          <li>
                            EC2 Instance Savings Plans: provides the lowest prices in exchange for commitment to usage
                            of individual instance families in a single Region. Results in savings up to 72%!
                          </li>
                        </ul>
                        Get started with{' '}
                        <a href="https://aws.amazon.com/savingsplans/" target="blank">
                          AWS Savings Plans
                        </a>
                        .
                      </ExpandableSection>
                      <ExpandableSection headerText="Over and under provisioning - what to do?">
                        Compute Optimizer helps customers avoid over and under provisioning four types of AWS resources
                        - EC2, EBS, ECS on Fargate and Lambda based on a customer’s utilization data. Customers get
                        improved recommendations for optimizing EC2 instances and can also find the EC2 workloads that
                        will deliver the biggest return for the smallest migration effort in a shift to AWS Graviton
                        CPUs. Get started with&nbsp;
                        <a href="https://aws.amazon.com/compute-optimizer/" target="blank">
                          Compute Optimizer
                        </a>
                        .
                      </ExpandableSection>
                      <ExpandableSection headerText="On premises to ROSA - which EC2 instances to choose?">
                        Customers unsure of what EC2 instance to use can specify their hardware requirements such as
                        vCPUs, Memory, network performance and local instance storage to get started. EC2 Instance
                        Discovery and Migration Hub will then give them a comparable list of instances across price,
                        performance. Get started with&nbsp;
                        <a
                          href="https://ap-southeast-2.console.aws.amazon.com/migrationhub/home?region=ap-southeast-2#/recommendations"
                          target="blank"
                        >
                          Migration Hub
                        </a>
                        .
                      </ExpandableSection>
                      <ExpandableSection headerText="Spot Instances support?">
                        Spot Instances allow customers to utilize unused EC2 capacity for up to 90% discount compared to
                        On-Demand prices.
                        <br />
                        Use cases:
                        <ul>
                          <li>
                            stateless or flexible applications such as big data, containerized workloads, CI/CD
                            pipelines and so forth
                          </li>
                          <li>
                            Not usually suited to long-running workloads because EC2 can reclaim the instances back with
                            two-minutes of notice.
                          </li>
                        </ul>
                        However, customers have the option to hibernate, stop or terminate their Spot Instances when
                        this happens.
                        <br />
                        Spot price is determined by long term trends in supply and demand for EC2 spare capacity.
                        <br />
                        More specifically, customers pay the Spot price that's in effect at the beginning of each
                        instance-hour for their running instance, billed to the nearest second.
                        <br /> <br />
                        <Link
                          external
                          href="https://cloud.redhat.com/blog/a-guide-to-red-hat-openshift-and-aws-spot-instances"
                        >
                          AWS Spot Instances
                        </Link>
                      </ExpandableSection>
                      <ExpandableSection headerText="Need to go deeper with a cost optimization workshop? (AWS only)">
                        See AWS Compute Optimization Workshop (TAM-led).
                      </ExpandableSection>
                      <ExpandableSection headerText="Other resources">
                        <ul>
                          <li>
                            <Link external href="https://pages.awscloud.com/apn-tv-571.html">
                              Pricing and Cost Control
                            </Link>
                          </li>
                          <li>
                            <Link
                              external
                              href="https://aws.amazon.com/blogs/containers/red-hat-openshift-service-on-aws-rosa-annual-contracts/"
                            >
                              Annual Contracts
                            </Link>
                          </li>
                          <li>
                            <Link
                              external
                              href="https://aws.amazon.com/blogs/opensource/cost-tracking-for-openshift-on-aws/"
                            >
                              Cost Tracking on AWS
                            </Link>
                          </li>
                          <li>
                            <Link
                              external
                              href="https://cloud.redhat.com/blog/guiding-developers-to-rightsize-their-openshift-applications"
                            >
                              Guide to right-sizing
                            </Link>
                          </li>
                        </ul>
                      </ExpandableSection>
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </>
    )
  );
}

function EstimateLegend() {
  return (
    <>
      <SpaceBetween size="s">
        <small>
          Monthlyised* All Upfront: Annual cost divided by 12 for monthly comparison purposes. 1 year or 3 year All
          Upfront Payment is required to be All Upfront and does not follow an annual or monthly payment schedule.
        </small>
        <small>
          Annuallyised* All Upfront: Annual cost divided by 3 years for annual comparison purposes. 3 year All Upfront
          Payment is required to be All Upfront and does not follow an annual or monthly payment schedule.
        </small>
      </SpaceBetween>
    </>
  );
}

function SummaryBarChart(props) {
  const { estimateClassic, estimateHCP } = props;

  const data = [
    {
      key: 'On-Demand',
      group: 'On-Demand Classic (3 years)',
      value: estimateClassic.estimateTotal.onDemand.annual * 3,
    },
    {
      key: 'On-Demand',
      group: 'On-Demand HCP (3 years)',
      value: estimateHCP.estimateTotal.onDemand.annual * 3,
    },
    {
      key: 'Compute Saving Plans (SP)',
      group: 'Compute SP Classic (3 years)',
      value: estimateClassic.estimateTotal.savingsPlanComputeNoUpfront3y.annually * 3,
    },
    {
      key: 'Compute Saving Plans (SP)',
      group: 'Compute SP HCP (3 years)',
      value: estimateHCP.estimateTotal.savingsPlanComputeNoUpfront3y.annually * 3,
    },
    {
      key: 'EC2 Instance Saving Plans (SP)',
      group: 'EC2 Instance SP Classic (3 years)',
      value: estimateClassic.estimateTotal.savingsPlanEC2NoUpfront1y.annually * 3,
    },
    {
      key: 'EC2 Instance Saving Plans (SP)',
      group: 'EC2 Instance SP HCP (3 years)',
      value: estimateHCP.estimateTotal.savingsPlanEC2NoUpfront3y.annually * 3,
    },
    // {
    //   key: 'Reserved Instances',
    //   group: 'RI Classic (3 years)',
    //   value: estimateClassic.estimateTotal.reservedAllUpfrontThreeYear.annually * 3,
    // },
    // {
    //   key: 'Reserved Instances',
    //   group: 'RI HCP (3 years)',
    //   value: estimateHCP.estimateTotal.reservedAllUpfrontThreeYear.annually * 3,
    // },
  ];

  const options = {
    axes: {
      left: {
        mapsTo: 'value',
      },
      bottom: {
        scaleType: 'labels',
        mapsTo: 'key',
      },
    },
    height: '400px',
  };

  return <GroupedBarChart data={data} options={options}></GroupedBarChart>;
}

function getEstimateItemsSummary(estimateClassic, estimateHCP) {
  return [
    {
      mode: 'Classic',
      description: 'On-Demand',
      '1y': estimateClassic.estimateTotal.onDemand.annual,
      '3y': estimateClassic.estimateTotal.onDemand.annual * 3,
    },
    {
      mode: 'HCP',
      description: 'On-Demand',
      '1y': estimateHCP.estimateTotal.onDemand.annual,
      '3y': estimateHCP.estimateTotal.onDemand.annual * 3,
      classicSaving1y:
        ((estimateClassic.estimateTotal.onDemand.annual - estimateHCP.estimateTotal.onDemand.annual) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
      classicSaving3y:
        ((estimateClassic.estimateTotal.onDemand.annual - estimateHCP.estimateTotal.onDemand.annual) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
    },
    {
      mode: 'Classic',
      description: 'Compute SP',
      '1y': estimateClassic.estimateTotal.savingsPlanComputeNoUpfront1y.annually,
      classicSaving1y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateClassic.estimateTotal.savingsPlanComputeNoUpfront1y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
      '3y': estimateClassic.estimateTotal.savingsPlanComputeNoUpfront3y.annually * 3,
      classicSaving3y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateClassic.estimateTotal.savingsPlanComputeNoUpfront3y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
    },
    {
      mode: 'HCP',
      description: 'Compute SP',
      '1y': estimateHCP.estimateTotal.savingsPlanComputeNoUpfront1y.annually,
      classicSaving1y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateHCP.estimateTotal.savingsPlanComputeNoUpfront1y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
      '3y': estimateHCP.estimateTotal.savingsPlanComputeNoUpfront3y.annually * 3,
      classicSaving3y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateHCP.estimateTotal.savingsPlanComputeNoUpfront3y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
    },
    {
      mode: 'Classic',
      description: 'EC2 Instance SP',
      '1y': estimateClassic.estimateTotal.savingsPlanEC2NoUpfront1y.annually,
      classicSaving1y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateClassic.estimateTotal.savingsPlanEC2NoUpfront1y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
      '3y': estimateClassic.estimateTotal.savingsPlanEC2NoUpfront3y.annually * 3,
      classicSaving3y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateClassic.estimateTotal.savingsPlanEC2NoUpfront3y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
    },
    // {
    //   description: 'Classic - RI All Upfront',
    //   '1y': estimateClassic.estimateTotal.reservedAllUpfrontOneYear.annually,
    //   classicSaving1y:
    //     ((estimateClassic.estimateTotal.onDemand.annual -
    //       estimateClassic.estimateTotal.reservedAllUpfrontOneYear.annually) /
    //       estimateClassic.estimateTotal.onDemand.annual) *
    //     100,
    //   '3y': estimateClassic.estimateTotal.reservedAllUpfrontThreeYear.annually * 3,
    //   classicSaving3y:
    //     ((estimateClassic.estimateTotal.onDemand.annual -
    //       estimateClassic.estimateTotal.reservedAllUpfrontThreeYear.annually) /
    //       estimateClassic.estimateTotal.onDemand.annual) *
    //     100,
    // },

    {
      mode: 'HCP',
      description: 'EC2 Instance SP',
      '1y': estimateHCP.estimateTotal.savingsPlanEC2NoUpfront1y.annually,
      classicSaving1y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateHCP.estimateTotal.savingsPlanEC2NoUpfront1y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
      '3y': estimateHCP.estimateTotal.savingsPlanEC2NoUpfront3y.annually * 3,
      classicSaving3y:
        ((estimateClassic.estimateTotal.onDemand.annual -
          estimateHCP.estimateTotal.savingsPlanEC2NoUpfront3y.annually) /
          estimateClassic.estimateTotal.onDemand.annual) *
        100,
    },
    // {
    //   description: 'HCP - RI All Upfront',
    //   '1y': estimateHCP.estimateTotal.reservedAllUpfrontOneYear.annually,
    //   classicSaving1y:
    //     ((estimateClassic.estimateTotal.onDemand.annual -
    //       estimateHCP.estimateTotal.reservedAllUpfrontOneYear.annually) /
    //       estimateClassic.estimateTotal.onDemand.annual) *
    //     100,
    //   '3y': estimateHCP.estimateTotal.reservedAllUpfrontThreeYear.annually * 3,
    //   classicSaving3y:
    //     ((estimateClassic.estimateTotal.onDemand.annual -
    //       estimateHCP.estimateTotal.reservedAllUpfrontThreeYear.annually) /
    //       estimateClassic.estimateTotal.onDemand.annual) *
    //     100,
    // },
  ];
}

function SummaryTable(props) {
  const { estimateClassic, estimateHCP, header } = props;

  const items = getEstimateItemsSummary(estimateClassic, estimateHCP);

  const columnDefinitions = [
    {
      id: 'mode',
      header: 'ROSA Mode',
      cell: item => item.mode,
      width: 100,
      maxWidth: 100,
    },
    {
      id: 'description',
      header: 'Pricing option',
      cell: item => item.description,
      width: 150,
      maxWidth: 150,
    },
    {
      id: '1y',
      header: '1 year',
      cell: item => currency(item['1y']),
      width: 150,
      maxWidth: 150,
    },
    {
      id: '3y',
      header: '3 years',
      cell: item => currency(item['3y']),
      width: 150,
      maxWidth: 150,
    },
  ];

  return (
    <>
      <Table
        header={<h3>{header}</h3>}
        resizableColumns
        stripedRows
        contentDensity="compact"
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText="Loading cost estimate"
        sortingDisabled
        variant="embedded"
      />
    </>
  );
}

function getEstimateItems(estimate) {
  return [
    {
      description: estimate.estimate.redHatClusterFees.description,
      onDemandMonthly: estimate.estimate.redHatClusterFees.monthly,
      onDemandAnnually: estimate.estimate.redHatClusterFees.annually,
      reservedAllUpfront1yMonthlyised: estimate.estimate.redHatClusterFees.monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.redHatClusterFees.annually,
      reservedAllUpfront3yMonthlyised: estimate.estimate.redHatClusterFees.monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.redHatClusterFees.annually,
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.redHatClusterFees.monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.redHatClusterFees.annually,
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.redHatClusterFees.monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.redHatClusterFees.annually,
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.redHatClusterFees.monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.redHatClusterFees.annually,
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.redHatClusterFees.monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.redHatClusterFees.annually,
    },
    {
      description: estimate.estimate.controlPlane.description,
      onDemandMonthly: estimate.estimate.controlPlane.onDemand.monthly,
      onDemandAnnually: estimate.estimate.controlPlane.onDemand.annually,

      // RI All Upfront 1y
      reservedAllUpfront1yMonthlyised: estimate.estimate.controlPlane['reservedAllUpfront1y'].monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.controlPlane['reservedAllUpfront1y'].annually,

      // RI All Upfront 3y
      reservedAllUpfront3yMonthlyised: estimate.estimate.controlPlane['reservedAllUpfront3y'].monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.controlPlane['reservedAllUpfront3y'].annually,

      // Compute SP No Upfront 1y
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.controlPlane['savingsPlanComputeNoUpfront1y'].monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized:
        estimate.estimate.controlPlane['savingsPlanComputeNoUpfront1y'].annually,
      // Compute SP No Upfront 3y
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.controlPlane['savingsPlanComputeNoUpfront3y'].monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized:
        estimate.estimate.controlPlane['savingsPlanComputeNoUpfront3y'].annually,

      // EC2 Instance SP No Upfront 1y
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.controlPlane['savingsPlanEC2NoUpfront1y'].monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.controlPlane['savingsPlanEC2NoUpfront1y'].annually,
      // EC2 Instance SP No Upfront 3y
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.controlPlane['savingsPlanEC2NoUpfront3y'].monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.controlPlane['savingsPlanEC2NoUpfront3y'].annually,
    },
    {
      description: estimate.estimate.infra.description,
      onDemandMonthly: estimate.estimate.infra.onDemand.monthly,
      onDemandAnnually: estimate.estimate.infra.onDemand.annually,

      // RI All Upfront 1y
      reservedAllUpfront1yMonthlyised: estimate.estimate.infra['reservedAllUpfront1y'].monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.infra['reservedAllUpfront1y'].annually,

      // RI All Upfront 3y
      reservedAllUpfront3yMonthlyised: estimate.estimate.infra['reservedAllUpfront3y'].monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.infra['reservedAllUpfront3y'].annually,

      // SP No Upfront 1y
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.infra['savingsPlanComputeNoUpfront1y'].monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.infra['savingsPlanComputeNoUpfront1y'].annually,

      // SP No Upfront 3y
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.infra['savingsPlanComputeNoUpfront3y'].monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.infra['savingsPlanComputeNoUpfront3y'].annually,

      // SP No Upfront 1y
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.infra['savingsPlanEC2NoUpfront1y'].monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.infra['savingsPlanEC2NoUpfront1y'].annually,

      // SP No Upfront 3y
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.infra['savingsPlanEC2NoUpfront3y'].monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.infra['savingsPlanEC2NoUpfront3y'].annually,
    },
    {
      description: estimate.estimate.workers.description,
      onDemandMonthly: estimate.estimate.workers.onDemand.monthly,
      onDemandAnnually: estimate.estimate.workers.onDemand.annually,

      // RI All Upfront 1y
      reservedAllUpfront1yMonthlyised: estimate.estimate.workers['reservedAllUpfront1y'].monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.workers['reservedAllUpfront1y'].annually,

      // RI All Upfront 3y
      reservedAllUpfront3yMonthlyised: estimate.estimate.workers['reservedAllUpfront3y'].monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.workers['reservedAllUpfront3y'].annually,

      // SP No Upfront 1y
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.workers['savingsPlanComputeNoUpfront1y'].monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.workers['savingsPlanComputeNoUpfront1y'].annually,
      // SP No Upfront 3y
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.workers['savingsPlanComputeNoUpfront3y'].monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.workers['savingsPlanComputeNoUpfront3y'].annually,

      // SP No Upfront 1y
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.workers['savingsPlanEC2NoUpfront1y'].monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.workers['savingsPlanEC2NoUpfront1y'].annually,
      // SP No Upfront 3y
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.workers['savingsPlanEC2NoUpfront3y'].monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.workers['savingsPlanEC2NoUpfront3y'].annually,
    },

    {
      description: estimate.estimate.redHatDataplaneFees.description,
      onDemandMonthly: estimate.estimate.redHatDataplaneFees.onDemand.monthly,
      onDemandAnnually: estimate.estimate.redHatDataplaneFees.onDemand.annually,
      reservedAllUpfront1yMonthlyised: estimate.estimate.redHatDataplaneFees['1year'].monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.redHatDataplaneFees['1year'].annually,
      reservedAllUpfront3yMonthlyised: estimate.estimate.redHatDataplaneFees['3year'].monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.redHatDataplaneFees['3year'].annually,
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.redHatDataplaneFees['1year'].monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.redHatDataplaneFees['1year'].annually,
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.redHatDataplaneFees['3year'].monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.redHatDataplaneFees['3year'].annually,
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.redHatDataplaneFees['1year'].monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.redHatDataplaneFees['1year'].annually,
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.redHatDataplaneFees['3year'].monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.redHatDataplaneFees['3year'].annually,
    },
    {
      description: estimate.estimate.storageWorkers.description,
      onDemandMonthly: estimate.estimate.storageWorkers.monthly,
      onDemandAnnually: estimate.estimate.storageWorkers.annually,
      reservedAllUpfront1yMonthlyised: estimate.estimate.storageWorkers.monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.storageWorkers.annually,
      reservedAllUpfront3yMonthlyised: estimate.estimate.storageWorkers.monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.storageWorkers.annually,
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.storageWorkers.monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.storageWorkers.annually,
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.storageWorkers.monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.storageWorkers.annually,
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.storageWorkers.monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.storageWorkers.annually,
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.storageWorkers.monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.storageWorkers.annually,
    },
    {
      description: estimate.estimate.storageInfra.description,
      onDemandMonthly: estimate.estimate.storageInfra.monthly,
      onDemandAnnually: estimate.estimate.storageInfra.annually,
      reservedAllUpfront1yMonthlyised: estimate.estimate.storageInfra.monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.storageInfra.annually,
      reservedAllUpfront3yMonthlyised: estimate.estimate.storageInfra.monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.storageInfra.annually,
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.storageInfra.monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.storageInfra.annually,
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.storageInfra.monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.storageInfra.annually,
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.storageInfra.monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.storageInfra.annually,
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.storageInfra.monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.storageInfra.annually,
    },
    {
      description: estimate.estimate.storageControlPlane.description,
      onDemandMonthly: estimate.estimate.storageControlPlane.monthly,
      onDemandAnnually: estimate.estimate.storageControlPlane.annually,
      reservedAllUpfront1yMonthlyised: estimate.estimate.storageControlPlane.monthly,
      reservedAllUpfront1yAnnually: estimate.estimate.storageControlPlane.annually,
      reservedAllUpfront3yMonthlyised: estimate.estimate.storageControlPlane.monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimate.storageControlPlane.annually,
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimate.storageControlPlane.monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimate.storageControlPlane.annually,
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimate.storageControlPlane.monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimate.storageControlPlane.annually,
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimate.storageControlPlane.monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimate.storageControlPlane.annually,
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimate.storageControlPlane.monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimate.storageControlPlane.annually,
    },
    {
      description: 'Total USD',
      onDemandMonthly: estimate.estimateTotal.onDemand.monthly,
      onDemandAnnually: estimate.estimateTotal.onDemand.annual,

      // RI All Upfront 1y
      reservedAllUpfront1yMonthlyised: estimate.estimateTotal.reservedAllUpfrontOneYear.monthly,
      reservedAllUpfront1yAnnually: estimate.estimateTotal.reservedAllUpfrontOneYear.annually,

      // RI All Upfront 3y
      reservedAllUpfront3yMonthlyised: estimate.estimateTotal.reservedAllUpfrontThreeYear.monthly,
      reservedAllUpfront3yAnnuallyized: estimate.estimateTotal.reservedAllUpfrontThreeYear.annually,

      // SP No Upfront 1y
      savingsPlanComputeNoUpfront1yMonthlyized: estimate.estimateTotal.savingsPlanComputeNoUpfront1y.monthly,
      savingsPlanComputeNoUpfront1yAnnuallyized: estimate.estimateTotal.savingsPlanComputeNoUpfront1y.annually,

      // SP No Upfront 3y
      savingsPlanComputeNoUpfront3yMonthlyized: estimate.estimateTotal.savingsPlanComputeNoUpfront3y.monthly,
      savingsPlanComputeNoUpfront3yAnnuallyized: estimate.estimateTotal.savingsPlanComputeNoUpfront3y.annually,

      // SP No Upfront 1y
      savingsPlanEC2NoUpfront1yMonthlyized: estimate.estimateTotal.savingsPlanEC2NoUpfront1y.monthly,
      savingsPlanEC2NoUpfront1yAnnuallyized: estimate.estimateTotal.savingsPlanEC2NoUpfront1y.annually,

      // SP No Upfront 3y
      savingsPlanEC2NoUpfront3yMonthlyized: estimate.estimateTotal.savingsPlanEC2NoUpfront3y.monthly,
      savingsPlanEC2NoUpfront3yAnnuallyized: estimate.estimateTotal.savingsPlanEC2NoUpfront3y.annually,
    },
  ];
}

function EstimateTable(props) {
  const { estimate, header } = props;

  const items = getEstimateItems(estimate);

  const columnDefinitions = [
    {
      id: 'description',
      header: 'Description',
      cell: item => item.description || '-',
      minWidth: 350,
    },
    {
      id: 'onDemandMonthly',
      header: 'On-Demand Monthly',
      cell: item => <Box float="right">{currency(item.onDemandMonthly) || '-'}</Box>,
    },
    {
      id: 'onDemandAnnually',
      header: 'On-Demand Annually',
      cell: item => <Box float="right">{currency(item.onDemandAnnually) || '-'}</Box>,
    },
    // {
    //   id: 'reservedAllUpfront1yMonthlyised',
    //   header: '1-Year  - RI Monthlyised* - All upfront',
    //   cell: item => <Box float="right">{currency(item.reservedAllUpfront1yMonthlyised) || '-'}</Box>,
    // },
    // {
    //   id: 'reservedAllUpfront1yAnnually',
    //   header: '1-Year  - RI Annually - All upfront',
    //   cell: item => <Box float="right">{currency(item.reservedAllUpfront1yAnnually) || '-'}</Box>,
    // },
    // {
    //   id: 'reservedAllUpfront3yMonthlyised',
    //   header: '3-Year  - RI Monthlyised* - All upfront',
    //   cell: item => <Box float="right">{currency(item.reservedAllUpfront3yMonthlyised) || '-'}</Box>,
    // },
    // {
    //   id: 'reservedAllUpfront3yAnnuallyized',
    //   header: '3-Year  - RI Annuallyised** - All upfront',
    //   cell: item => <Box float="right">{currency(item.reservedAllUpfront3yAnnuallyized) || '-'}</Box>,
    // },
    {
      id: 'savingsPlanComputeNoUpfront1yMonthlyized',
      header: '1-Year  - Compute SPs Monthly - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanComputeNoUpfront1yMonthlyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanComputeNoUpfront1yAnnuallyized',
      header: '1-Year  - Compute SPs Annually - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanComputeNoUpfront1yAnnuallyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanComputeNoUpfront3yMonthlyized',
      header: '3-Year - Compute SPs Monthly - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanComputeNoUpfront3yMonthlyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanComputeNoUpfront3yAnnuallyized',
      header: '3-Year - Compute SPs Annually - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanComputeNoUpfront3yAnnuallyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanEC2NoUpfront1yMonthlyized',
      header: '1-Year  - EC2 Instance SPs Monthly - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanEC2NoUpfront1yMonthlyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanEC2NoUpfront1yAnnuallyized',
      header: '1-Year  - EC2 Instance SPs Annually - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanEC2NoUpfront1yAnnuallyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanEC2NoUpfront3yMonthlyized',
      header: '3-Year - EC2 Instance SPs Monthly - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanEC2NoUpfront3yMonthlyized) || '-'}</Box>,
    },
    {
      id: 'savingsPlanEC2NoUpfront3yAnnuallyized',
      header: '3-Year - EC2 Instance SPs Annually - No upfront',
      cell: item => <Box float="right">{currency(item.savingsPlanEC2NoUpfront3yAnnuallyized) || '-'}</Box>,
    },
  ];

  return (
    <>
      <Table
        header={<h3>{header}</h3>}
        resizableColumns
        stripedRows
        wrapLines
        contentDensity="compact"
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText="Loading cost estimate"
        sortingDisabled
        variant="embedded"
      />
    </>
  );
}

function currency(amount) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Math.round(amount));
}
