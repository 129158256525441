// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  COLUMN_DEFINITIONS,
  VISIBLE_CONTENT_OPTIONS,
  PAGE_SIZE_OPTIONS,
  SEARCHABLE_COLUMNS,
} from './table-select-filter-config';
import { CollectionPreferences, Input, Pagination, Select, Table } from '@cloudscape-design/components';
import '../../styles/table-select.scss';
import { instances, getInstances } from '../../data/instances';
import { regions } from '../../data/regions';
import { getTextFilterCounterText, getHeaderCounterText, paginationAriaLabels } from '../../i18n-strings';
import { TableEmptyState, TableNoMatchState } from '../commons/common-components';
import { FullPageHeader } from '../commons';
import { useColumnWidths } from '../commons/use-column-widths';
import { useLocalStorage } from '../commons/use-local-storage';
import Container from '@cloudscape-design/components/container';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Button } from '@cloudscape-design/components';
import FormField from '@cloudscape-design/components/form-field';

import { getWorkerNodes, getEstimate } from '../../lib/cost';

import { EstimateView } from './EstimateView';

const withSideEffect =
  (fn, sideEffect) =>
  (...args) => {
    sideEffect(...args);
    return fn(...args);
  };

const regionLabels = regions.map(region => {
  return `${region.full_name} - ${region.code}`;
});

const defaultRegion = { value: regionLabels[0].code, label: regionLabels[0].label };
const selectRegion = prepareSelectRegion('region', defaultRegion);

function groupNodes(instances) {
  // Step 1: Count occurrences using an object
  const counts = {};

  instances.forEach(instance => {
    const key = instance[0]; // Extract the instance type from the sub-array
    if (counts[key]) {
      counts[key]++;
    } else {
      counts[key] = 1;
    }
  });

  // Step 2: Convert the counts object to an array of objects
  const result = Object.keys(counts).map(key => ({
    type: key,
    count: counts[key],
  }));

  return result;
}

function prepareSelectRegion(field, defaultOption) {
  const optionSet = [];
  // Building a non redundant list of the field passed as parameter.

  regionLabels.forEach(item => {
    // if (optionSet.indexOf(item[field]) === -1) {
    optionSet.push(item);
    // }
  });
  optionSet.sort();

  // The first element is the default one.
  const options = [defaultOption];

  // Adding the other element ot the list.
  regions.forEach(region => {
    options.push({ label: `${region.full_name} - ${region.code}`, value: region.code });
  });
  return options;
}

export function TableSelectFilter() {
  const [columnDefinitions, saveWidths] = useColumnWidths('React-TableSelectFilter-Widths', COLUMN_DEFINITIONS);
  const [region, setRegion] = useState(defaultRegion);

  const [preferences, setPreferences] = useLocalStorage('React-InstancesTable-Preferences', {
    pageSize: 5,
    visibleContent: ['id', 'count', 'category', 'cpu', 'memory', 'ondemandcost'],
    wrapLines: false,
    stripedRows: false,
    custom: 'table',
  });

  const [tableItems, setTableItems] = useState(instances);
  const persistChanges = () => {
    setTableItems(tableItems);
  };
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    tableItems,
    {
      filtering: {
        empty: <TableEmptyState resourceName="Instance" />,
        noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
        filteringFunction: (item, filteringText) => {
          const filteringTextLowerCase = filteringText.toLowerCase();

          return SEARCHABLE_COLUMNS.map(key => item[key]).some(
            value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
          );
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
      selection: {},
    }
  );

  const tablePaginationProps = {
    ...paginationProps,
    onChange: withSideEffect(paginationProps.onChange, persistChanges),
  };

  const onRefresh = async () => {
    // persistChanges();
    setTableItems(await getInstances());
  };
  const refreshButtonProps = { onClick: onRefresh };

  useLayoutEffect(() => {
    collectionProps.ref.current?.scrollToTop();
  }, [region, collectionProps.ref, filterProps.filteringText]);

  function clearFilter() {
    actions.setFiltering('');
    setRegion(defaultRegion);
  }

  const [estimateClassicMultiAz, setEstimateClassicMultiAz] = useState();
  const [estimateClassicSingleAz, setEstimateClassicSingleAz] = useState();
  const [estimateOCPMultiAz, setEstimateOCPMultiAz] = useState();
  const [estimateHCP, setEstimateHCP] = useState();

  const [hasNodes, setHasNodes] = useState();
  const [nodes, setNodes] = useState();
  const [ebsPrices, setEBSPrices] = useState();
  const [ec2Prices, setEC2Prices] = useState();
  const [error, setError] = useState();
  const [errorReason, setErrorReason] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // set region if present in the URL
    const searchParams = new URLSearchParams(location.search);
    const regionParam = searchParams.get('region');
    if (regionParam) {
      setRegion({ value: regionParam });
    }
  }, [location.search]);

  useLayoutEffect(() => {
    // set nodes if present in the URL
    const searchParams = new URLSearchParams(location.search);
    const nodesParam = searchParams.get('nodes');
    if (nodesParam) {
      const nodes = JSON.parse(nodesParam);
      nodes.forEach(node => {
        const foundIndex = tableItems.findIndex(type => type.id === node.type);
        if (foundIndex !== -1) {
          tableItems[foundIndex].count = node.count;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useLayoutEffect(() => {
    // set region when changing it in dropdown
    if (region.value) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('region', region.value);

      const currentPath = location.pathname;
      navigate(`${currentPath}?${searchParams.toString()}`);
    }
  }, [location.pathname, location.search, navigate, region, setRegion]);

  useLayoutEffect(() => {
    // set nodes when changing it in table
    if (nodes) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('nodes', nodes);

      const currentPath = location.pathname;
      navigate(`${currentPath}?${searchParams.toString()}`);
    }
  }, [location.pathname, location.search, navigate, nodes, region, setRegion]);

  useLayoutEffect(() => {
    if (!region.value) {
      return;
    }
    fetch(`/prices/${region.value}-ebs.json`)
      .then(res => res.json(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(
        resultEbs => {
          setEBSPrices(resultEbs);

          fetch(`/prices/${region.value}-ec2.json`)
            .then(res => res.json(), {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })
            .then(
              resultEC2 => {
                setEC2Prices(resultEC2);

                let localNodes = [['ec2_type']];
                tableItems.forEach(element => {
                  if (element.count > 0) {
                    for (let i = 0; i < element.count; i++) {
                      localNodes.push([element.id]);
                    }
                  }
                });

                if (localNodes.slice(1, localNodes.length).length > 0) {
                  setNodes(JSON.stringify(groupNodes(localNodes.slice(1, localNodes.length)))); // add nodes to URL
                }

                setError(null);
                setErrorReason(null);

                const HOURS_PER_MONTH = 730;

                const rosaWorkerFeesOnDemand = 0.171; // 1500/12/HOURS_PER_MONTH
                const rosaWorkerFees1year = 1000 / 12 / HOURS_PER_MONTH;
                const rosaWorkerFees3year = 667 / 12 / HOURS_PER_MONTH;

                const workerNodesROSA = getWorkerNodes(
                  localNodes,
                  resultEC2,
                  rosaWorkerFeesOnDemand,
                  rosaWorkerFees1year,
                  rosaWorkerFees3year
                );
                if (workerNodesROSA.error) {
                  setError(workerNodesROSA.error);
                  setErrorReason('Add instances or try another instance type available in the selected region');
                } else {
                  setHasNodes(true); // workerNodesROSA.length > 0 && workerNodesOCP.length > 0);
                  setEstimateClassicMultiAz(getEstimate(workerNodesROSA, 3, resultEC2, resultEbs, 0));
                  setEstimateClassicSingleAz(getEstimate(workerNodesROSA, 2, resultEC2, resultEbs, 0));
                  setEstimateHCP(getEstimate(workerNodesROSA, 0, resultEC2, resultEbs, 0.25));
                }
              },
              error => {
                setError(error);
              }
            );
        },
        error => {
          setError(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, setNodes, region, setRegion, tableItems, setTableItems]);

  useLayoutEffect(() => {
    if (!region.value) {
      return;
    }
    fetch(`/prices/${region.value}-ebs.json`)
      .then(res => res.json(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(
        resultEbs => {
          setEBSPrices(resultEbs);

          fetch(`/prices/${region.value}-ec2.json`)
            .then(res => res.json(), {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })
            .then(
              resultEC2 => {
                setEC2Prices(resultEC2);

                const localNodes = [['ec2_type']];

                tableItems.forEach(element => {
                  if (element.count > 0) {
                    for (let i = 0; i < element.count; i++) {
                      localNodes.push([element.id]);
                    }
                  }
                });

                if (localNodes.slice(1, localNodes.length).length > 0) {
                  setNodes(JSON.stringify(groupNodes(localNodes.slice(1, localNodes.length)))); // add nodes to URL
                }

                setError(null);
                setErrorReason(null);

                const ocpWorkerFees = 0.653;

                const workerNodesOCP = getWorkerNodes(
                  localNodes,
                  resultEC2,
                  ocpWorkerFees,
                  ocpWorkerFees,
                  ocpWorkerFees
                );
                if (workerNodesOCP.error) {
                  setError(workerNodesOCP.error);
                  setErrorReason('Add instances or try another instance type available in the selected region');
                } else {
                  setEstimateOCPMultiAz(getEstimate(workerNodesOCP, 3, resultEC2, resultEbs, 0));
                }
              },
              error => {
                setError(error);
              }
            );
        },
        error => {
          setError(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, setRegion, tableItems, setTableItems]);

  const handleSubmit = (currentItem, column, value) => {
    // await new Promise(resolve => setTimeout(resolve, 1500));
    let fullCollection = tableItems;

    const newItem = { ...currentItem, [column.id]: value };

    if (filterProps.filteringText) {
      fullCollection = tableItems;
    }

    setTableItems(fullCollection.map(item => (item.id === currentItem.id ? newItem : item)));
  };

  return (
    <>
      <SpaceBetween size="l">
        <Table
          {...collectionProps}
          columnDefinitions={columnDefinitions}
          visibleColumns={preferences.visibleContent}
          items={items}
          submitEdit={handleSubmit}
          variant="full-page"
          resizableColumns={true}
          contentDensity="compact"
          onColumnWidthsChange={saveWidths}
          wrapLines={preferences.wrapLines}
          stripedRows={preferences.stripedRows}
          header={
            <FullPageHeader
              title="Red Hat OpenShift offerings on AWS - Pricing calculator"
              selectedItemsCount={collectionProps.selectedItems.length}
              counter={`(${getHeaderCounterText(tableItems, collectionProps.selectedItems)} instances)`}
              extraActions={
                <>
                  <Button data-testid="header-btn-view-details" {...refreshButtonProps}>
                    Reset instances
                  </Button>
                </>
              }
            />
          }
          filter={
            <div className="input-container">
              <div className="input-filter">
                <FormField
                  description="Search instance(s), then specify their total number in the table below"
                  label="Select Amazon EC2 instances"
                >
                  <Input
                    data-testid="input-filter"
                    type="search"
                    value={filterProps.filteringText}
                    onChange={event => {
                      actions.setFiltering(event.detail.value);
                    }}
                    placeholder="Find instances"
                    label="Find instances"
                    clearAriaLabel="Clear"
                    ariaDescribedby={null}
                  />
                </FormField>
              </div>
              <div className="select-filter">
                <FormField
                  description="AWS region where ROSA/OpenShift is deployed."
                  errorText={region.value === undefined ? 'No region selected.' : ''}
                  label="Select an AWS region"
                >
                  <Select
                    data-testid="region-filter"
                    options={selectRegion}
                    selectedAriaLabel="Selected"
                    selectedOption={region}
                    onChange={event => {
                      setRegion(event.detail.selectedOption);
                    }}
                    ariaDescribedby={null}
                    expandToViewport={true}
                  />
                </FormField>
              </div>
              {(filterProps.filteringText || region !== defaultRegion) && (
                <span className="filtering-results">{getTextFilterCounterText(filteredItemsCount)}</span>
              )}
            </div>
          }
          pagination={<Pagination {...tablePaginationProps} ariaLabels={paginationAriaLabels} />}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={{
                title: 'Page size',
                options: PAGE_SIZE_OPTIONS,
              }}
              wrapLinesPreference={{
                label: 'Wrap lines',
                description: 'Check to see all the text and wrap the lines',
              }}
              stripedRowsPreference={{
                label: 'Striped rows',
                description: 'Check to add alternating shaded rows',
              }}
              visibleContentPreference={{
                title: 'Select visible columns',
                options: VISIBLE_CONTENT_OPTIONS,
              }}
            />
          }
        />
        {region.value && (
          <Container>
            <EstimateView
              tableItems={tableItems}
              region={region.value}
              estimateClassicMultiAz={estimateClassicMultiAz}
              estimateClassicSingleAz={estimateClassicSingleAz}
              estimateOCPMultiAz={estimateOCPMultiAz}
              estimateHCP={estimateHCP}
              hasNodes={hasNodes}
              ebsPrices={ebsPrices}
              ec2Prices={ec2Prices}
              error={error}
              errorReason={errorReason}
            />
          </Container>
        )}
      </SpaceBetween>
    </>
  );
}
