// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { getEbsPrice } from './price';

export type Estimate = {
  estimate: {
    controlPlane: {
      description: any;
      onDemand: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront1y: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront3y: {
        monthly: any;
        annually: any;
      };
    };
    infra: {
      description: any;
      onDemand: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront1y: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront3y: {
        monthly: any;
        annually: any;
      };
    };
    workers: {
      description: any;
      onDemand: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront1y: {
        monthly: any;
        annually: any;
      };
      reservedAllUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanComputeNoUpfront3y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront1y: {
        monthly: any;
        annually: any;
      };
      savingsPlanEC2NoUpfront3y: {
        monthly: any;
        annually: any;
      };
    };
    storageWorkers: {
      description: any;
      monthly: any;
      annually: any;
    };
    storageInfra: {
      description: any;
      monthly: any;
      annually: any;
    };
    storageControlPlane: {
      description: any;
      monthly: any;
      annually: any;
    };
    redHatClusterFees: {
      description: any;
      monthly: any;
      annually: any;
    };
    redHatDataplaneFees: {
      description: any;
      onDemand: {
        monthly: any;
        annually: any;
      };
      '1year': {
        monthly: any;
        annually: any;
      };
      '3year': {
        monthly: any;
        annually: any;
      };
    };
  };
  estimateTotal: {
    onDemand: {
      monthly: any;
      annual: any;
    };
    reservedAllUpfrontOneYear: {
      monthly: any;
      annually: any;
    };
    reservedAllUpfrontThreeYear: {
      monthly: any;
      annually: any;
    };
    savingsPlanComputeNoUpfront1y: {
      monthly: any;
      annually: any;
    };
    savingsPlanComputeNoUpfront3y: {
      monthly: any;
      annually: any;
    };
    savingsPlanEC2NoUpfront1y: {
      monthly: any;
      annually: any;
    };
    savingsPlanEC2NoUpfront3y: {
      monthly: any;
      annually: any;
    };
  };
};

export function getEstimate(
  workerNodes: WorkerNode[],
  infraNodesCount: number,
  ec2Prices: EC2[],
  ebsPrices: any,
  clusterFeeHourly: number
): Estimate {
  let ec2OnDemandMonthlyCost = 0;
  let reservedAllUpfront1yMonthlyCost = 0;
  let reservedAllUpfront3yMonthlyCost = 0;
  let savingsPlanComputeNoUpfront1yMonthlyCost = 0;
  let savingsPlanComputeNoUpfront3yMonthlyCost = 0;
  let savingsPlanEC2NoUpfront1yMonthlyCost = 0;
  let savingsPlanEC2NoUpfront3yMonthlyCost = 0;
  let rhOnDemandMonthlyCost = 0;
  let rh1yearMonthlyCost = 0;
  let rh3yearMonthlyCost = 0;

  const rosaWorkerNodesArray = Object.values(workerNodes);
  const workerNodeCount = workerNodes.length;

  const HOURS_PER_MONTH = 730;

  for (const node of rosaWorkerNodesArray) {
    ec2OnDemandMonthlyCost += node.onDemandPrice * HOURS_PER_MONTH;
    reservedAllUpfront1yMonthlyCost += node.reservedAllUpfront1yrPrice / 12;
    reservedAllUpfront3yMonthlyCost += node.reservedAllUpfront3yrPrice / 3 / 12;
    savingsPlanComputeNoUpfront1yMonthlyCost += node.savingsPlanComputeNoUpfront1yPrice / 12;
    savingsPlanComputeNoUpfront3yMonthlyCost += node.savingsPlanComputeNoUpfront3yPrice / 12;
    savingsPlanEC2NoUpfront1yMonthlyCost += node.savingsPlanEC2NoUpfront1yPrice / 12;
    savingsPlanEC2NoUpfront3yMonthlyCost += node.savingsPlanEC2NoUpfront3yPrice / 12;

    rhOnDemandMonthlyCost += node.rhOnDemandPriceHour * HOURS_PER_MONTH;
    rh1yearMonthlyCost += node.rh1yearPriceHour * HOURS_PER_MONTH;
    rh3yearMonthlyCost += node.rh3yearPriceHour * HOURS_PER_MONTH;
  }
  let ec2TypeInfra = 'r5.xlarge';
  if (workerNodeCount > 25 && workerNodeCount <= 100) {
    ec2TypeInfra = 'r5.2xlarge';
  } else if (workerNodeCount > 100) {
    ec2TypeInfra = 'r5.4xlarge';
  }

  const ec2Infra = (ec2Prices || []).find(ec2 => ec2.type === ec2TypeInfra);

  if (!ec2Infra) {
    throw new Error(`Infra node price not found (EC2 type: ${ec2TypeInfra})`);
  }

  const unitCostInfraOnDemand = Number(ec2Infra.priceOnDemand);
  const unitCostInfraReservedAllUpfront1year = Number(ec2Infra.reservedAllUpfront1yr);
  const unitCostInfraReservedAllUpfront3year = Number(ec2Infra.reservedAllUpfront3yr);
  const unitCostInfraSavingsPlanComputeNoUpfront1y = Number(ec2Infra.savingsPlanComputeNoUpfront1y);
  const unitCostInfraSavingsPlanComputeNoUpfront3y = Number(ec2Infra.savingsPlanComputeNoUpfront3y);
  const unitCostInfraSavingsPlanEC2NoUpfront1y = Number(ec2Infra.savingsPlanEC2NoUpfront1y);
  const unitCostInfraSavingsPlanEC2NoUpfront3y = Number(ec2Infra.savingsPlanEC2NoUpfront3y);

  let ec2TypeControlPlane = 'm5.2xlarge';
  if (workerNodeCount > 25 && workerNodeCount <= 100) {
    ec2TypeControlPlane = 'm5.4xlarge';
  } else if (workerNodeCount > 100) {
    ec2TypeControlPlane = 'm5.8xlarge';
  }

  const ec2Cplane = (ec2Prices || []).find(ec2 => ec2.type === ec2TypeControlPlane);

  if (!ec2Cplane) {
    throw new Error(`Control plane node price not found (EC2 type: ${ec2TypeControlPlane})`);
  }

  let controlPlaneNodesCount = 3;
  if (infraNodesCount === 0) {
    // ROSA version is HCP if infra node count === 0
    controlPlaneNodesCount = 0;
  }
  const ebsMonthlyPriceOthers = getEbsPrice(300, ebsPrices);
  const ebsMonthlyPriceHcp = getEbsPrice(300 + 100, ebsPrices); // 3 volumes per node for HCP
  const ebsMonthlyPriceControlPlane = getEbsPrice(350, ebsPrices);

  const unitCostControlPlaneOnDemand = Number(ec2Cplane.priceOnDemand);
  const unitCostControlPlaneReservedAllUpfront1year = Number(ec2Cplane.reservedAllUpfront1yr);
  const unitCostControlPlaneReservedAllUpfront3year = Number(ec2Cplane.reservedAllUpfront3yr);
  const unitCostControlPlaneSavingsPlanComputeNoUpfront1y = Number(ec2Cplane.savingsPlanComputeNoUpfront1y);
  const unitCostControlPlaneSavingsPlanComputeNoUpfront3y = Number(ec2Cplane.savingsPlanComputeNoUpfront3y);
  const unitCostControlPlaneSavingsPlanEC2NoUpfront1y = Number(ec2Cplane.savingsPlanEC2NoUpfront1y);
  const unitCostControlPlaneSavingsPlanEC2NoUpfront3y = Number(ec2Cplane.savingsPlanEC2NoUpfront3y);

  const estimate = {
    workers: {
      description: `AWS Data Plane Cost (EC2): ${workerNodeCount}x nodes (${workerNodes.map(
        workerNode => workerNode.ec2Type
      )})`,
      onDemand: {
        monthly: ec2OnDemandMonthlyCost,
        annually: ec2OnDemandMonthlyCost * 12,
      },
      reservedAllUpfront1y: {
        monthly: reservedAllUpfront1yMonthlyCost,
        annually: reservedAllUpfront1yMonthlyCost * 12,
      },
      reservedAllUpfront3y: {
        monthly: reservedAllUpfront3yMonthlyCost,
        annually: reservedAllUpfront3yMonthlyCost * 12,
      },
      savingsPlanComputeNoUpfront1y: {
        monthly: savingsPlanComputeNoUpfront1yMonthlyCost,
        annually: savingsPlanComputeNoUpfront1yMonthlyCost * 12,
      },
      savingsPlanComputeNoUpfront3y: {
        monthly: savingsPlanComputeNoUpfront3yMonthlyCost,
        annually: savingsPlanComputeNoUpfront3yMonthlyCost * 12,
      },
      savingsPlanEC2NoUpfront1y: {
        monthly: savingsPlanEC2NoUpfront1yMonthlyCost,
        annually: savingsPlanEC2NoUpfront1yMonthlyCost * 12,
      },
      savingsPlanEC2NoUpfront3y: {
        monthly: savingsPlanEC2NoUpfront3yMonthlyCost,
        annually: savingsPlanEC2NoUpfront3yMonthlyCost * 12,
      },
    },
    infra: {
      description: `AWS Infra Node Cost (EC2): ${infraNodesCount}x ${ec2TypeInfra} nodes`,
      onDemand: {
        monthly: unitCostInfraOnDemand * infraNodesCount * HOURS_PER_MONTH,
        annually: unitCostInfraOnDemand * infraNodesCount * HOURS_PER_MONTH * 12,
        calculations: {
          monthly: `= $${unitCostInfraOnDemand} EC2 price 1 infra. node * ${infraNodesCount} nodes * ${HOURS_PER_MONTH}h`,
          annually: `= $${unitCostInfraOnDemand} EC2 price 1 infra. node * ${infraNodesCount} nodes * ${HOURS_PER_MONTH}h * 12`,
        },
      },
      reservedAllUpfront1y: {
        monthly: (unitCostInfraReservedAllUpfront1year * infraNodesCount) / 12,
        annually: unitCostInfraReservedAllUpfront1year * infraNodesCount,
        calculations: {
          monthly: `= $${unitCostInfraReservedAllUpfront1year} EC2 price 1 infra. node / 1 year (All upfront) ÷ 12 * ${infraNodesCount} nodes`,
          annually: `= $${unitCostInfraReservedAllUpfront1year} EC2 price 1 infra. node / 1 year (All upfront) * ${infraNodesCount} nodes`,
        },
      },
      reservedAllUpfront3y: {
        monthly: ((unitCostInfraReservedAllUpfront3year / 3) * infraNodesCount) / 12,
        annually: (unitCostInfraReservedAllUpfront3year / 3) * infraNodesCount,
        calculations: {
          monthly: `= $${unitCostInfraReservedAllUpfront3year} EC2 price 1 infra. node / 3 year (All upfront) * ${infraNodesCount} nodes ÷ 3`,
          annually: `= $${unitCostInfraReservedAllUpfront3year} EC2 price 1 infra. node / 3 year (All upfront) * ${infraNodesCount} nodes ÷ 3`,
        },
      },
      savingsPlanComputeNoUpfront1y: {
        monthly: (unitCostInfraSavingsPlanComputeNoUpfront1y / 12) * infraNodesCount,
        annually: unitCostInfraSavingsPlanComputeNoUpfront1y * infraNodesCount,
      },
      savingsPlanComputeNoUpfront3y: {
        monthly: (unitCostInfraSavingsPlanComputeNoUpfront3y / 12) * infraNodesCount,
        annually: unitCostInfraSavingsPlanComputeNoUpfront3y * infraNodesCount,
      },
      savingsPlanEC2NoUpfront1y: {
        monthly: (unitCostInfraSavingsPlanEC2NoUpfront1y / 12) * infraNodesCount,
        annually: unitCostInfraSavingsPlanEC2NoUpfront1y * infraNodesCount,
      },
      savingsPlanEC2NoUpfront3y: {
        monthly: (unitCostInfraSavingsPlanEC2NoUpfront3y / 12) * infraNodesCount,
        annually: unitCostInfraSavingsPlanEC2NoUpfront3y * infraNodesCount,
      },
    },
    controlPlane: {
      description: `AWS Control Plane Cost (EC2): 3x ${ec2TypeControlPlane}`,
      onDemand: {
        monthly: unitCostControlPlaneOnDemand * controlPlaneNodesCount * HOURS_PER_MONTH,
        annually: unitCostControlPlaneOnDemand * controlPlaneNodesCount * HOURS_PER_MONTH * 12,
        calculations: {
          monthly: `$${unitCostControlPlaneOnDemand} EC2 price control plane node * ${controlPlaneNodesCount} nodes * ${HOURS_PER_MONTH}h`,
          annually: `$${unitCostControlPlaneOnDemand} EC2 price control plane node * ${controlPlaneNodesCount} nodes * ${HOURS_PER_MONTH}h * 12`,
        },
      },
      reservedAllUpfront1y: {
        monthly: (unitCostControlPlaneReservedAllUpfront1year * controlPlaneNodesCount) / 12,
        annually: unitCostControlPlaneReservedAllUpfront1year * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneReservedAllUpfront1year} EC2 price control plane node 1 year (All upfront) ÷ 12 * ${controlPlaneNodesCount} nodes`,
          annually: `$${unitCostControlPlaneReservedAllUpfront1year} EC2 price control plane node 1 year (All upfront) * ${controlPlaneNodesCount} nodes`,
        },
      },
      reservedAllUpfront3y: {
        monthly: ((unitCostControlPlaneReservedAllUpfront3year / 3) * controlPlaneNodesCount) / 12,
        annually: (unitCostControlPlaneReservedAllUpfront3year / 3) * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneReservedAllUpfront3year}$ EC2 price control plane node 3 year (All upfront) ÷ 12 ÷ 3 * ${controlPlaneNodesCount} nodes`,
          annually: `$${unitCostControlPlaneReservedAllUpfront3year}$ EC2 price control plane node 3 year (All upfront) ÷ 3 * ${controlPlaneNodesCount} nodes`,
        },
      },
      savingsPlanComputeNoUpfront1y: {
        monthly: (unitCostControlPlaneSavingsPlanComputeNoUpfront1y * controlPlaneNodesCount) / 12,
        annually: unitCostControlPlaneSavingsPlanComputeNoUpfront1y * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneSavingsPlanComputeNoUpfront1y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes ÷ 12`,
          annually: `$${unitCostControlPlaneSavingsPlanComputeNoUpfront1y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
        },
      },
      savingsPlanComputeNoUpfront3y: {
        monthly: (unitCostControlPlaneSavingsPlanComputeNoUpfront3y * controlPlaneNodesCount) / 12,
        annually: unitCostControlPlaneSavingsPlanComputeNoUpfront3y * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneSavingsPlanComputeNoUpfront3y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
          annually: `$${unitCostControlPlaneSavingsPlanComputeNoUpfront3y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
        },
      },
      savingsPlanEC2NoUpfront1y: {
        monthly: (unitCostControlPlaneSavingsPlanEC2NoUpfront1y * controlPlaneNodesCount) / 12,
        annually: unitCostControlPlaneSavingsPlanEC2NoUpfront1y * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneSavingsPlanEC2NoUpfront1y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes ÷ 12`,
          annually: `$${unitCostControlPlaneSavingsPlanEC2NoUpfront1y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
        },
      },
      savingsPlanEC2NoUpfront3y: {
        monthly: (unitCostControlPlaneSavingsPlanEC2NoUpfront3y * controlPlaneNodesCount) / 12,
        annually: unitCostControlPlaneSavingsPlanEC2NoUpfront3y * controlPlaneNodesCount,
        calculations: {
          monthly: `$${unitCostControlPlaneSavingsPlanEC2NoUpfront3y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
          annually: `$${unitCostControlPlaneSavingsPlanEC2NoUpfront3y}$ EC2 price control plane node 1 year (No upfront) * 3 nodes`,
        },
      },
    },
    redHatClusterFees: {
      description: `Red Hat Control Plane Fee ($${clusterFeeHourly} per hour cluster fee)`,
      monthly: clusterFeeHourly * HOURS_PER_MONTH,
      annually: clusterFeeHourly * HOURS_PER_MONTH * 12,
      calculations: {
        monthly: `$${clusterFeeHourly} * ${HOURS_PER_MONTH}h`,
        annually: `$${clusterFeeHourly} * ${HOURS_PER_MONTH}h * 12`,
      },
    },
    redHatDataplaneFees: {
      description: `Red Hat Data Plane Fee: ${workerNodeCount}x nodes (${workerNodes.map(
        workerNode => workerNode.ec2Type
      )})`,
      onDemand: {
        monthly: rhOnDemandMonthlyCost,
        annually: rhOnDemandMonthlyCost * 12,
      },
      '1year': {
        monthly: rh1yearMonthlyCost,
        annually: rh1yearMonthlyCost * 12,
      },
      '3year': {
        monthly: rh3yearMonthlyCost,
        annually: rh3yearMonthlyCost * 12,
      },
    },
    storageWorkers: {
      description: `AWS Storage Cost (EBS): ${workerNodeCount}x worker nodes 300GB General Purpose SSDs (gp3 - 3000 IOPS - 125 Mbps Throughput - No snapshot storage)`,
      monthly: ebsMonthlyPriceOthers * workerNodeCount,
      annually: ebsMonthlyPriceOthers * workerNodeCount * 12,
    },
    storageInfra: {
      description: `AWS Storage Cost (EBS): ${infraNodesCount}x infra nodes 300GB General Purpose SSDs (gp3 - 3000 IOPS - 125 Mbps Throughput - No snapshot storage)`,
      monthly: ebsMonthlyPriceOthers * infraNodesCount,
      annually: ebsMonthlyPriceOthers * infraNodesCount * 12,
    },
    storageControlPlane: {
      description: `AWS Storage Cost (EBS): ${controlPlaneNodesCount}x control plane nodes 350GB General Purpose SSDs (gp3 - 3000 IOPS - 125 Mbps Throughput - No snapshot storage)`,
      monthly: ebsMonthlyPriceControlPlane * controlPlaneNodesCount,
      annually: ebsMonthlyPriceControlPlane * controlPlaneNodesCount * 12,
    },
  };

  const totalOnDemand =
    estimate.workers.onDemand.annually +
    estimate.infra.onDemand.annually +
    estimate.controlPlane.onDemand.annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees.onDemand.annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalReservedAllUpfrontOneYear =
    estimate.workers['reservedAllUpfront1y'].annually +
    estimate.infra['reservedAllUpfront1y'].annually +
    estimate.controlPlane['reservedAllUpfront1y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['1year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalReservedAllUpfrontThreeYear =
    estimate.workers['reservedAllUpfront3y'].annually +
    estimate.infra['reservedAllUpfront3y'].annually +
    estimate.controlPlane['reservedAllUpfront3y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['3year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalSavingsPlanComputeNoUpfront1y =
    estimate.workers['savingsPlanComputeNoUpfront1y'].annually +
    estimate.infra['savingsPlanComputeNoUpfront1y'].annually +
    estimate.controlPlane['savingsPlanComputeNoUpfront1y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['1year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalSavingsPlanComputeNoUpfront3y =
    estimate.workers['savingsPlanComputeNoUpfront3y'].annually +
    estimate.infra['savingsPlanComputeNoUpfront3y'].annually +
    estimate.controlPlane['savingsPlanComputeNoUpfront3y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['3year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalSavingsPlanEC2NoUpfront1y =
    estimate.workers['savingsPlanEC2NoUpfront1y'].annually +
    estimate.infra['savingsPlanEC2NoUpfront1y'].annually +
    estimate.controlPlane['savingsPlanEC2NoUpfront1y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['1year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const totalSavingsPlanEC2NoUpfront3y =
    estimate.workers['savingsPlanEC2NoUpfront3y'].annually +
    estimate.infra['savingsPlanEC2NoUpfront3y'].annually +
    estimate.controlPlane['savingsPlanEC2NoUpfront3y'].annually +
    estimate.redHatClusterFees.annually +
    estimate.redHatDataplaneFees['3year'].annually +
    estimate.storageWorkers.annually +
    estimate.storageInfra.annually +
    estimate.storageControlPlane.annually;

  const estimateTotal = {
    onDemand: {
      monthly: totalOnDemand / 12,
      annual: totalOnDemand,
    },
    reservedAllUpfrontOneYear: {
      monthly: totalReservedAllUpfrontOneYear / 12,
      annually: totalReservedAllUpfrontOneYear,
    },
    reservedAllUpfrontThreeYear: {
      monthly: totalReservedAllUpfrontThreeYear / 12,
      annually: totalReservedAllUpfrontThreeYear,
    },
    savingsPlanComputeNoUpfront1y: {
      monthly: totalSavingsPlanComputeNoUpfront1y / 12,
      annually: totalSavingsPlanComputeNoUpfront1y,
    },
    savingsPlanComputeNoUpfront3y: {
      monthly: totalSavingsPlanComputeNoUpfront3y / 12,
      annually: totalSavingsPlanComputeNoUpfront3y,
    },
    savingsPlanEC2NoUpfront1y: {
      monthly: totalSavingsPlanEC2NoUpfront1y / 12,
      annually: totalSavingsPlanEC2NoUpfront1y,
    },
    savingsPlanEC2NoUpfront3y: {
      monthly: totalSavingsPlanEC2NoUpfront3y / 12,
      annually: totalSavingsPlanEC2NoUpfront3y,
    },
  };

  return {
    estimate,
    estimateTotal,
  };
}

const generalPurposeFamilies = [
  'm7g',
  'm7i',
  'm7i-flex',
  'm7a',
  'mac',
  'm6g',
  'm6i',
  'm6in',
  'm6a',
  'm5',
  'm5n',
  'm5zn',
  'm5a',
  'm4',
  't4g',
  't3',
  't3a',
  't2',
];

const computeOptimizedFamilies = [
  'c7g',
  'c7gn',
  'c7i',
  'c7i-flex',
  'c7a',
  'c6g',
  'c6gn',
  'c6i',
  'c6in',
  'c6a',
  'c5',
  'c5n',
  'c5a',
  'c4',
];

const memoryOptimizedFamilies = [
  'r8g',
  'r7g',
  'r7i',
  'r7iz',
  'r7a',
  'r6g',
  'r6i',
  'r6in',
  'r6a',
  'r5',
  'r5n',
  'r5b',
  'r5a',
  'r4',
  'u7i',
  // "High Memory (U-1)",
  'x2gd',
  'x2idn',
  'x2iedn',
  'x2iezn',
  'x1',
  'x1e',
  'z1d',
];

const gpuFamilies = [
  'p5',
  'p4',
  'p3',
  'p2',
  'g6',
  'g5g',
  'g5',
  'g4dn',
  'g4ad',
  'g3',
  'trn1',
  'inf2',
  'inf1',
  'dl1',
  'dl2q',
  'f1',
  'vt1',
];

const storageFamilies = ['i4g', 'im4gn', 'Is4gen', 'i4i', 'i3', 'i3en', 'd3', 'd3en', 'd2', 'h1'];

const hpcFamilies = ['hpc7g', 'hpc7a', 'hpc6id', 'hpc6a'];

export function generateInstanceFamilyVariants(ec2Type: string) {
  const [family, size] = ec2Type.split('.');
  if (family === null) {
    throw new Error(`Error extracting family from: ${ec2Type})`);
  }
  const familyType = family.match(/^[a-zA-Z]+/)[0];

  const familyArrays = [
    ...generalPurposeFamilies,
    ...computeOptimizedFamilies,
    ...memoryOptimizedFamilies,
    ...gpuFamilies,
    ...storageFamilies,
    ...hpcFamilies,
  ];

  const closestFamilies: string[] = [];

  familyArrays.forEach(f => {
    const fFamilyType = f.match(/^[a-zA-Z]+/)[0];
    if (fFamilyType === familyType) {
      closestFamilies.push(`${f}.${size}`);
      closestFamilies.push(`${f}a.${size}`);
      closestFamilies.push(`${f}ad.${size}`);
      closestFamilies.push(`${f}b.${size}`);
      closestFamilies.push(`${f}d.${size}`);
      closestFamilies.push(`${f}de.${size}`);
      closestFamilies.push(`${f}dn.${size}`);
      closestFamilies.push(`${f}g.${size}`);
      closestFamilies.push(`${f}gd.${size}`);
      closestFamilies.push(`${f}gn.${size}`);
      closestFamilies.push(`${f}gen.${size}`);
      closestFamilies.push(`${f}e.${size}`);
      closestFamilies.push(`${f}en.${size}`);
      closestFamilies.push(`${f}i.${size}`);
      closestFamilies.push(`${f}iedn.${size}`);
      closestFamilies.push(`${f}iezn.${size}`);
      closestFamilies.push(`${f}iz.${size}`);
      closestFamilies.push(`${f}i-flex.${size}`);
      closestFamilies.push(`${f}in.${size}`);
      closestFamilies.push(`${f}id.${size}`);
      closestFamilies.push(`${f}idn.${size}`);
      closestFamilies.push(`${f}n.${size}`);
      closestFamilies.push(`${f}s.${size}`);
      closestFamilies.push(`${f}zn.${size}`);
    }
  });

  return closestFamilies;
}

export function removeDuplicatesByType(array: EC2[]): EC2[] {
  const seen = new Set();
  return (array || []).filter(item => {
    const value = item.type;
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}

export const getCheaperUnitPricesVariants = (ec2Prices: EC2[], ec2TypeSearch: string): EC2[] => {
  const ec2Types = [ec2TypeSearch, ...generateInstanceFamilyVariants(ec2TypeSearch)];
  const results1: EC2[] = [];

  ec2Types.forEach(ec2Type => {
    ec2Prices.forEach(ec2 => {
      if (ec2.type === ec2Type) {
        results1.push(ec2);
      }
    });
  });

  const results2 = removeDuplicatesByType(results1);

  const priceAttributes = [
    'priceOnDemand',
    'reservedAllUpfront1yr',
    'reservedAllUpfront3yr',
    'savingsPlanComputeNoUpfront1y',
    'savingsPlanComputeNoUpfront3y',
    'savingsPlanEC2NoUpfront1y',
    'savingsPlanEC2NoUpfront3y',
  ];

  function comparePrices(a: EC2, b: EC2): number {
    for (const attr of priceAttributes) {
      const priceA = parseFloat(a[attr as keyof EC2]);
      const priceB = parseFloat(b[attr as keyof EC2]);
      if (priceA !== priceB) {
        return priceA - priceB;
      }
    }
    return 0;
  }

  const results3 = results2.sort(comparePrices);

  function getInstancesUntilType(instances: EC2[], type: string): EC2[] {
    const index = instances.findIndex(instance => instance.type === type);
    return index !== -1 ? instances.slice(0, index + 1) : instances;
  }

  return getInstancesUntilType(results3, ec2TypeSearch);
};

export type WorkerNode = {
  ec2Type: string;
  onDemandPrice: number;
  reservedAllUpfront1yrPrice: number;
  reservedAllUpfront3yrPrice: number;
  savingsPlanComputeNoUpfront1yPrice: number;
  savingsPlanComputeNoUpfront3yPrice: number;
  savingsPlanEC2NoUpfront1yPrice: number;
  savingsPlanEC2NoUpfront3yPrice: number;
  rhOnDemandPriceHour: number;
  rh1yearPriceHour: number;
  rh3yearPriceHour: number;
};

type EC2 = {
  type: string;
  priceOnDemand: string;
  vcpu: string;
  memory: string;
  reservedAllUpfront1yr: string;
  reservedAllUpfront3yr: string;
  savingsPlanComputeNoUpfront1y: string;
  savingsPlanComputeNoUpfront3y: string;
  savingsPlanEC2NoUpfront1y: string;
  savingsPlanEC2NoUpfront3y: string;
};

type Error = {
  error: string;
};

export function getWorkerNodes(
  nodes: any,
  ec2s: EC2[],
  workerFeesOnDemand: number,
  workerFees1year: number,
  workerFees3year: number
): WorkerNode[] | Error {
  if (nodes.length < 2) {
    return {
      error: 'No EC2 instances added',
    };
  }

  if (nodes[0][0] !== 'ec2_type') {
    return { error: 'CSV header missing: ec2_type' };
  }
  nodes.shift(); // remove csv headers

  const cpuCountCost = 4;
  const resultNodes: any[] = [];
  for (const row of nodes) {
    const ec2Type = row[0];
    const ec2 = (ec2s || []).find(ec2 => ec2.type === ec2Type);
    if (!ec2) {
      return { error: `EC2 type "${ec2Type}" not found in the Price API data` };
    }

    resultNodes.push({
      ec2Type,
      onDemandPrice: Number(ec2.priceOnDemand),
      reservedAllUpfront1yrPrice: Number(ec2.reservedAllUpfront1yr),
      reservedAllUpfront3yrPrice: Number(ec2.reservedAllUpfront3yr),
      savingsPlanComputeNoUpfront1yPrice: Number(ec2.savingsPlanComputeNoUpfront1y),
      savingsPlanComputeNoUpfront3yPrice: Number(ec2.savingsPlanComputeNoUpfront3y),
      savingsPlanEC2NoUpfront1yPrice: Number(ec2.savingsPlanEC2NoUpfront1y),
      savingsPlanEC2NoUpfront3yPrice: Number(ec2.savingsPlanEC2NoUpfront3y),
      rhOnDemandPriceHour: Number(workerFeesOnDemand * (Number(ec2.vcpu) / cpuCountCost)),
      rh1yearPriceHour: Number(workerFees1year * (Number(ec2.vcpu) / cpuCountCost)),
      rh3yearPriceHour: Number(workerFees3year * (Number(ec2.vcpu) / cpuCountCost)),
    });
  }

  return resultNodes;
}
